<template>
  <div class="mb-6" v-if="editor">
    <div class="flex pr-0 mb-1">
      <div class="flex-grow"></div>
      <div
        class="font-mono flex gap-x-0 divide-x rounded divide-gray-500 mb-0 justify-end border border-gray-500"
      >
        <button
          class="control"
          type="button"
          title="Bold"
          @click="editor.commands.toggleBold()"
          :class="{ 'is-active': editor.isActive('bold') }"
        >
          B
        </button>
        <button
          class="control"
          type="button"
          title="Italic"
          @click="editor.commands.toggleItalic()"
          :class="{ 'is-active': editor.isActive('italic') }"
        >
          I
        </button>
        <button
          class="control"
          type="button"
          :title="`Heading ${i}`"
          @click="editor.commands.toggleHeading({ level: i })"
          v-for="i in [1, 2, 3]"
          :class="{ 'is-active': editor.isActive('heading', { level: i }) }"
        >
          H<sub>{{ i }}</sub>
        </button>
        <button
          class="control"
          type="button"
          title="Undo"
          @click="editor.commands.undo()"
          :disabled="!editor.can().undo()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-4 h-4"
          >
            <path
              fill-rule="evenodd"
              d="M7.793 2.232a.75.75 0 01-.025 1.06L3.622 7.25h10.003a5.375 5.375 0 010 10.75H10.75a.75.75 0 010-1.5h2.875a3.875 3.875 0 000-7.75H3.622l4.146 3.957a.75.75 0 01-1.036 1.085l-5.5-5.25a.75.75 0 010-1.085l5.5-5.25a.75.75 0 011.06.025z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <button
          class="control"
          type="button"
          title="Undo"
          @click="editor.commands.redo()"
          :disabled="!editor.can().redo()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-4 h-4"
          >
            <path
              fill-rule="evenodd"
              d="M12.207 2.232a.75.75 0 00.025 1.06l4.146 3.958H6.375a5.375 5.375 0 000 10.75H9.25a.75.75 0 000-1.5H6.375a3.875 3.875 0 010-7.75h10.003l-4.146 3.957a.75.75 0 001.036 1.085l5.5-5.25a.75.75 0 000-1.085l-5.5-5.25a.75.75 0 00-1.06.025z"
              clip-rule="evenodd"
            />
          </svg>
        </button>

        <button
          class="control"
          type="button"
          title="Toggle Unordered List"
          @click="editor.chain().focus().toggleBulletList().run()"
          :class="{ 'is-active': editor.isActive('bulletList') }"
        >
          UL
        </button>
        <button
          class="control"
          type="button"
          title="Toggle Ordered List"
          @click="editor.chain().focus().toggleOrderedList().run()"
          :class="{ 'is-active': editor.isActive('orderedList') }"
        >
          OL
        </button>
        <button
          class="control"
          type="button"
          title="Sink List Item"
          @click="editor.chain().focus().sinkListItem('listItem').run()"
          :disabled="!editor.can().sinkListItem('listItem')"
        >
          &gt;
        </button>
        <button
          class="control"
          type="button"
          title="Lift List Item"
          @click="editor.chain().focus().liftListItem('listItem').run()"
          :disabled="!editor.can().liftListItem('listItem')"
        >
          &lt;
        </button>
      </div>
    </div>
    <editor-content :editor="editor" :key="props.name" />
  </div>
</template>

<script setup>
import { useEditor, EditorContent } from "@tiptap/vue-3"
import StarterKit from "@tiptap/starter-kit"
import ListItem from "@tiptap/extension-list-item"
import OrderedList from "@tiptap/extension-ordered-list"
import BulletList from "@tiptap/extension-bullet-list"

const props = defineProps(["name"])

const mirrorSelector = `textarea[data-mirror-name=${props.name}]`
const mirrorElement = document.querySelector(mirrorSelector)

const content = () => {
  try {
    return mirrorElement.value
  } catch (error) {}
  return ""
}

const editor = useEditor({
  content: content(),
  onUpdate: ({ editor }) => {
    const html = editor.getHTML()
    mirrorElement.value = html
  },
  extensions: [StarterKit],
})
</script>

<style lang="postcss">
.control:first-child {
  @apply rounded-l;
}
.control:last-child {
  @apply rounded-r;
}
.control {
  @apply flex items-center justify-center text-sm w-6 h-6 transition hover:bg-gray-200;
}
.is-active {
  @apply bg-gray-300;
}
button:disabled {
  @apply hover:bg-white text-gray-300 cursor-not-allowed;
}
</style>
