import { createApp, h } from "vue"
import Editor from "./components/editor.vue"

export const setupTipTap = async () => {
  if (
    ["scenes#new", "scenes#create", "scenes#edit", "scenes#update"].includes(
      document.querySelector("body")?.dataset?.controllerAction,
    )
  ) {
    window.setTimeout(() => {
      console.log("Running setupTipTap")
      const tiptaps = document.querySelectorAll(".tiptap")
      for (const tiptap of tiptaps) {
        const name = tiptap.dataset.name
        const selector = `.tiptap[data-name=${name}]`
        const app = createApp({ render: () => h(Editor) }, { name })
        app.mount(selector)
      }
    }, 200)
  }
}
